<template>
  <iq-card>
    <template v-slot:body>
      <b-overlay :show="loading">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
          <b-row>
            <b-col sm="4">
              <ValidationProvider name="Farmer Id" rules="required|min_value:1">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="farmer_id"
                  slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                    {{ $t('warehouse_service.farmer_id')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    readonly
                    id="farmer_id"
                    v-model="payment_collection.farmer_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Farmer Name">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="farmer_name"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_service.farmer_name')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="farmer_name"
                    v-model="payment_info.farmer_name"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Division" vid='division_id'>
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="warehouse_name"
                  slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('globalTrans.division')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                    id="warehouse_name"
                    readonly
                    v-model="payment_info.division"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Region" vid='region_id'>
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="region"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('globalTrans.region')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="region_id"
                    v-model="payment_info.region"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="District" vid='district_id'>
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="district"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('globalTrans.district')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="district_id"
                    v-model="payment_info.district"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Upazila" vid='upazilla_id'>
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="upazila"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('globalTrans.upazila')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="upazila_id"
                    v-model="payment_info.upazilla"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Warehouse Name">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="warehouse_name"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_service.warehouse_name')}} <span class="text-danger"></span>
                  </template>
                    <b-form-input
                      readonly
                    id="warehouse_id"
                    v-model="payment_info.warehouse"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Fiscal Year" >
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="fiscal_year"
                  slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                    {{ $t('warehouse_service.fiscal_year')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="fiscal_year"
                    v-model="payment_info.fiscal_year"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Crop Type" >
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="crop_type"
                  slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                    {{ $t('warehouse_service.crop_type')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="crop_type"
                    v-model="payment_info.crop_type"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Crop Name" >
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="crop_name"
                  slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                    {{ $t('warehouse_service.crop_name')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="crop_name"
                    v-model="payment_info.crop_name"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Attachment" vid="attachment">
                <b-form-group
                class="row"
                label-cols-sm="12"
                :label=" $t('warehouse_config.warehouse_attachments')"
                label-for="warehouse_attachments"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                {{ $t('warehouse_config.warehouse_attachments') }}
                </template>
                <b-form-file
                id="warehouse_attachments"
                v-on:change="onFileChange"
                :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Release Date" rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="release_date"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_service.date')}} <span class="text-danger">*</span>
                  </template>
                    <flat-pickr class="form-control"
                      id="release_date"
                      v-model="payment_collection.release_date"
                      placeholder="Select Date"
                      :state="errors[0] ? false : (valid ? true : null)"
                  ></flat-pickr>
                  <!-- <b-form-input
                    id="store_date"
                    type="date"
                    v-model="payment_collection.release_date"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input> -->
                  <div class="invalid-feedback d-block" :show="errors.length">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Store Date" rules="required|min_value:1">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="crop_name"
                  slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                    {{ $t('warehouse_service.store_date')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                    plain
                    v-model="payment_collection.far_req_entry_id"
                    :options="applicationLists"
                    id="far_req_entry_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    >
                    <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Quantity">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="total_quantity"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_service.total_quantity')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="total_quantity"
                    v-model="payment_collection.quantity"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4" v-show="present_quantity">
              <ValidationProvider name="Present Quantity">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="present_quantity"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_service.present_quantity_amount')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="total_quantity"
                    v-model="present_quantity"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4" v-show="present_quantity === 0">
              <ValidationProvider name="Present Quantity">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="present_quantity"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_service.present_quantity_amount')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="total_quantity"
                    v-model="present_quantity"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Rent Amount">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="rent_amount"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_service.rent_amount')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="rent_amount"
                    v-model="payment_collection.rent_amount"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Sack Number" vid='release_sack' rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="sack_number"
                  slot-scope="{ valid, errors }"
                >
                  <template v-slot:label>
                    {{ $t('warehouse_service.release_quantity_sack')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="sack_number"
                    type="number"
                    min='1'
                    v-model="payment_collection.release_sack"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Release Quantity" vid='release_quantity' rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="total_quantity"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_service.release_quantity_amount')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="total_quantity"
                    type="number"
                    min="1"
                    max="present_quantity"
                    v-model="payment_collection.release_quantity"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Remaining Quantity" vid='remain_quantity'>
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="remain_quantity"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('warehouse_service.remaining_quantity_amount')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                      readonly
                    id="remain_quantity"
                    v-model="payment_collection.remain_quantity"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Paid Rent Amount" vid='paid_rent_amount'>
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="paid_rent_amount"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouseFarmer.paid_rent_amount')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                    readonly
                    id="paid_rent_amount"
                    v-model="payment_collection.paid_rent_amount"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Payment Due" vid='payment_due' rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="payment_due"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouseFarmer.payment_due')}} <span class="text-danger"></span>
                  </template>
                  <b-form-input
                    readonly
                    id="payment_due"
                    v-model="payment_info.payment_due"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col sm="4">
              <ValidationProvider name="Bank Receipt" vid='bank_receipt_no' rules="required">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="bank_reciept_no"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                    {{ $t('warehouse_service.bank_reciept_no')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    id="bank_reciept_no"
                    v-model="payment_collection.bank_receipt_no"
                    :state="errors[0] ? false : (valid ? true : null)"
                    ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-right">
                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                <b-button variant="danger" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
      </b-overlay>
    </template>
  </iq-card>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { paymentCollectionStore } from '../../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getFarmerRequestData()
      // Object.freeze(tmp)
      this.payment_collection = tmp
    }
    this.getFarmerList()
    this.loadData()
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      payment_collection: {
         warehouse_id: '',
         farmer_id: '',
         far_req_entry_id: '0',
         crop_type_id: '',
         crop_id: '',
         fiscal_year_id: '',
         level_id: '',
         quantity: '',
         rent_amount: '',
         release_sack: '',
         release_quantity: '',
         paid_rent_amount: '',
         remain_quantity: '',
         bank_receipt_no: '',
         release_date: '',
         attachment: '',
         status: '0'
      },
      payment_info: {
        division: '',
        region: '',
        district: '',
        upazilla: '',
        warehouse: '',
        payment_due: '',
        crop_name: '',
        crop_type: '',
        farmer_name: '',
        level: '',
        fiscal_year: ''
      },
      rentPerUnit: '',
      attachment_two: '',
      hasQuantity: '',
      present_quantity: '',
      cropNameLists: [],
      levelList: [],
      capacity: '',
      applicationLists: [],
      district: [],
      dList: [],
      loading: false
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    }
  },
  watch: {
    'payment_collection.farmer_id': function (newVal, oldVal) {
      this.dList = this.getFarmerInfoList(newVal)
    },
    'payment_collection.far_req_entry_id': function (newVal, oldVal) {
      this.dList = this.getFarmerRequestEntryList(newVal)
    },
    'payment_collection.release_quantity': function (newVal, oldVal) {
      this.dList = this.getRemainAmount(newVal)
    },
    'payment_collection.paid_rent_amount': function (newVal, oldVal) {
      this.dList = this.getRemainPaymentAmount(newVal)
    }
  },
  methods: {
    getFarmerRequestData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getFarmerList () {
        RestApi.getData(authServiceBaseUrl, '/user/farmer-list').then(response => {
          if (response) {
              this.farmerList = response.data
          }
        })
    },
    getRemainAmount (releaseAmount) {
        let remaining = ''
        if (this.present_quantity === 0) {
            this.payment_collection.release_quantity = ''
            this.payment_collection.remain_quantity = '0'
        } else {
            if (this.present_quantity) {
                if (releaseAmount > this.present_quantity) {
                    this.payment_collection.release_quantity = ''
                } else {
                    this.errorMessage = ''
                    remaining = this.present_quantity - releaseAmount
                }
            } else {
                if (releaseAmount > this.payment_collection.quantity) {
                    this.payment_collection.release_quantity = ''
                } else {
                    this.errorMessage = ''
                    remaining = this.payment_collection.quantity - releaseAmount
                }
            }
            if (remaining >= 0 && this.payment_collection.quantity) {
                this.payment_collection.remain_quantity = remaining
            }
        }

        this.payment_collection.paid_rent_amount = this.rentPerUnit * releaseAmount
    },
    getRemainPaymentAmount (paidRentAmount) {
        if (paidRentAmount > this.payment_collection.rent_amount) {
            this.payment_collection.paid_rent_amount = ''
        } else {
            this.payment_info.payment_due = this.payment_collection.rent_amount - paidRentAmount
        }
    },
    onFileChange (e) {
      this.attachment_two = e.target.files[0]
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const loadingState = { loading: false, listReload: false }
      let result = null
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
        var formData = new FormData()
        Object.keys(this.payment_collection).map(key => {
            if (key === 'attachment') {
            formData.append(key, this.attachment_two)
            } else {
            formData.append(key, this.payment_collection[key])
            }
        })
        result = await RestApi.postData(warehouseServiceBaseUrl, paymentCollectionStore, formData, config)

      if (result.success) {
        loadingState.listReload = true
        this.$toast.success({
          title: 'Success',
          message: this.payment_collection.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        // this.$socket.emit('send-notification', result.admin_notification)
        this.$bvModal.hide('modal-4')
        this.loading = false
      } else {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
        this.loading = false
      }
       this.$store.dispatch('mutateCommonProperties', loadingState)
    },
    async getFarmerInfoList (farmerId = null) {
      this.loading = true
        await RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-payment-collection/farmer-info/' + farmerId).then(response => {
          if (response.success) {
              this.applicationLists = response.data.map(obj => {
                  if (this.$i18n.locale === 'en') {
                  return { value: obj.id, text: obj.application_id + '-' + obj.store_date }
                  } else {
                  return { value: obj.id, text: this.$n(obj.application_id, { useGrouping: false }) + '-' + this.$n(obj.store_date.split('-')[2], { useGrouping: false }) + '/' + this.$n(obj.store_date.split('-')[1], { useGrouping: false }) + '/' + this.$n(obj.store_date.split('-')[0], { useGrouping: false }) }
                  }
              })
            this.loading = false
          } else {
            this.applicationLists = []
            this.loading = false
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
    },
    async getFarmerRequestEntryList (Id = null) {
      this.loading = true
        await RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-payment-collection/farmer-request-entry/' + Id).then(response => {
            if (response.success) {
                var farmerId = response.data.id
                if (this.$i18n.locale === 'bn') {
                    this.payment_info.farmer_name = this.farmerList.find(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id).name_bn
                    this.payment_info.division = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].division_id)[0].text_bn
                    this.payment_info.region = this.$store.state.ExternalUserIrrigation.warehouse.regionList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].region_id)[0].text_bn
                    this.payment_info.district = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(upazila => upazila.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].district_id)[0].text_bn
                    this.payment_info.upazilla = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].upazilla_id)[0].text_bn
                    this.payment_info.warehouse = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].warehouse_id)[0].text_bn
                    this.payment_info.crop_name = this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.filter(document => document.value === response.data.crop_id)[0].text_bn
                    this.payment_info.crop_type = this.$store.state.ExternalUserIrrigation.warehouse.commodityGroupList.filter(document => document.value === response.data.crop_type_id)[0].text_bn
                    this.payment_collection.quantity = response.data.total_quantity
                } else {
                    this.payment_info.farmer_name = this.farmerList.find(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id).name
                    this.payment_info.division = this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].division_id)[0].text
                    this.payment_info.region = this.$store.state.ExternalUserIrrigation.warehouse.regionList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].region_id)[0].text
                    this.payment_info.district = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(upazila => upazila.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].district_id)[0].text
                    this.payment_info.upazilla = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].upazilla_id)[0].text
                    this.payment_info.warehouse = this.$store.state.ExternalUserIrrigation.warehouse.warehouseInfoList.filter(document => document.value === this.farmerList.filter(document => document.username === response.data.farmer_id || document.mobile_no === response.data.farmer_id)[0].warehouse_id)[0].text
                    this.payment_info.crop_name = this.$store.state.ExternalUserIrrigation.warehouse.commodityNameList.filter(document => document.value === response.data.crop_id)[0].text
                    this.payment_info.crop_type = this.$store.state.ExternalUserIrrigation.warehouse.commodityGroupList.filter(document => document.value === response.data.crop_type_id)[0].text
                    this.payment_collection.quantity = response.data.total_quantity
                }
                const year = this.$store.state.ExternalUserIrrigation.warehouse.fiscalYearList.find(document => document.value === response.rent.fiscal_year_id).text_en
                var fiscalYear = this.$n(year.split('-')[0], { useGrouping: false }) + '-' + this.$n(year.split('-')[1], { useGrouping: false })
                this.payment_info.fiscal_year = fiscalYear

                this.payment_collection.crop_id = response.data.crop_id
                this.payment_collection.crop_type_id = response.data.crop_type_id
                this.payment_collection.fiscal_year_id = response.rent.fiscal_year_id
                this.payment_collection.level_id = response.data.level_id
                this.payment_collection.warehouse_id = response.data.warehouse_id

                const presentDay = new Date()
                const storeDay = new Date(response.data.store_date)
                const diffTime = Math.abs(presentDay - storeDay)
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                var month = parseInt(diffDays / 30)
                const day = diffDays % 30
                if (day <= 15) {
                    month = month + 0.5
                } else {
                    month += 1
                }
                var rantingAmount = response.rent.renting_amount
                var totalQuantity = response.data.total_quantity
                this.rentPerUnit = rantingAmount * month
                var rent = 0

                RestApi.getData(warehouseServiceBaseUrl, '/warehouse-service-payment-collection/remaining-quantity/' + farmerId).then(response => {
                    if (response.success) {
                        if (response.data.remain_quantity > 0) {
                            this.present_quantity = response.data.remain_quantity
                            rent = rantingAmount * this.present_quantity * month
                             this.payment_collection.rent_amount = rent
                        } else {
                            this.present_quantity = 0
                        }
                      this.loading = false
                    } else {
                        this.present_quantity = ''
                        rent = rantingAmount * totalQuantity * month
                        this.payment_collection.rent_amount = rent
                      this.loading = false
                    }
                })
                this.payment_collection.release_sack = ''
                this.payment_collection.release_quantity = ''
                this.payment_collection.paid_rent_amount = ''
                this.payment_collection.bank_receipt_no = ''
            } else {
              this.loading = false
            }
        })
    },
    loadData () {
        // RestApi.getData(authServiceBaseUrl, '/auth-user').then(response => {
        // if (response.success) {
        //   this.payment_collection.farmer_id = response.data.user_detail_farmer.mobile_no
        //   }
        // })
      this.payment_collection.farmer_id = this.authUser.username ? this.authUser.username : null
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
