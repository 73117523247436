<template>
  <b-container fluid>
    <iq-card>
        <!-- <template v-slot:body>
    <b-row>
        <b-col>
        <b-form-group
          class="row"
          label-cols-sm="3"
          :label="$t('warehouse_service.farmer_id')"
          label-for="farmer_id"
        >
          <b-form-input
            id="farmer_id"
            v-model="search.farmer_id"
            placeholder=""
            ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col></b-col>
    </b-row>
      <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
      </template> -->
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service.make_payment') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(name)="data">
                        <span class="capitalize">
                          {{ $i18n.locale === 'bn' ? farmerList.filter(document => document.username === data.item.farmer_id || document.mobile_no === data.item.farmer_id)[0].name_bn : farmerList.filter(document => document.username === data.item.farmer_id || document.mobile_no === data.item.farmer_id)[0].name}}
                        </span>
                    </template>
                    <template v-slot:cell(farmer_id)="data">
                        <span class="capitalize">
                          {{ $n(0) }}{{ $n(data.item.farmer_id, { useGrouping: false }) }}
                        </span>
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(commodity_name)="data">
                      <span class="capitalize">{{ data.item.commodity_name }}</span>
                    </template>
                    <template v-slot:cell(commodity_group_name)="data">
                      <span class="capitalize">{{ data.item.commodity_group_name }}</span>
                    </template>
                    <template v-slot:cell(rent_amount)="data">
                      <span class="capitalize">{{ $n(data.item.rent_amount) }}</span>
                    </template>
                    <template v-slot:cell(paid_rent_amount)="data">
                      <span class="capitalize">{{ $n(data.item.paid_rent_amount) }}</span>
                    </template>
                    <template v-slot:cell(release_quantity)="data">
                      <span class="capitalize">{{ $n(data.item.release_quantity) }}</span>
                    </template>
                    <template v-slot:cell(remain_quantity)="data">
                      <span class="capitalize">{{ $n(data.item.remain_quantity) }}</span>
                    </template>
                    <template v-slot:cell(bank_receipt_no)="data">
                      <span class="capitalize">{{ $n(data.item.bank_receipt_no, { useGrouping: false }) }}</span>
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.pending') : $t('globalTrans.approved') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <b-button v-b-modal.modal-7 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-eye-fill m-0"></i></b-button>
                      <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="testId" :key="testId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>

import FormV from './Form'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { paymentCollectionList, userStatus } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  name: 'UiDataTable',
  components: {
    FormV
  },
  data () {
    return {
      search: {
          farmer_id: this.$store.state.Auth.authUser.username
      },
      testId: 0,
      farmerList: [],
      rows: []
    }
  },
  computed: {
    formTitle () {
       return (this.testId === 0) ? this.$t('warehouse_service.make_payment') : this.$t('warehouse_service.farmer_request') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouse_service.farmer_name'), class: 'text-left' },
          { label: this.$t('warehouse_service.farmer_id'), class: 'text-left' },
          { label: this.$t('warehouse_service.warehouse'), class: 'text-left' },
          { label: this.$t('warehouse_service.crop_name'), class: 'text-left' },
          { label: this.$t('warehouse_service.crop_type'), class: 'text-left' },
          { label: this.$t('warehouse_service.rent_amount'), class: 'text-left' },
          { label: this.$t('warehouseFarmer.paid_rent_amount'), class: 'text-left' },
          { label: this.$t('warehouse_service.release_quantity_amount'), class: 'text-left' },
          { label: this.$t('warehouse_service.remaining_quantity_amount'), class: 'text-left' },
          { label: this.$t('warehouse_service.bank_reciept_no'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'farmer_id' },
          { key: 'warehouse_name_bn' },
          { key: 'commodity_name_bn' },
          { key: 'commodity_group_name_bn' },
          { key: 'rent_amount' },
          { key: 'paid_rent_amount' },
          { key: 'release_quantity' },
          { key: 'remain_quantity' },
          { key: 'bank_receipt_no' },
          { key: 'status' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'farmer_id' },
          { key: 'warehouse_name' },
          { key: 'commodity_name' },
          { key: 'commodity_group_name' },
          { key: 'rent_amount' },
          { key: 'paid_rent_amount' },
          { key: 'release_quantity' },
          { key: 'remain_quantity' },
          { key: 'bank_receipt_no' },
          { key: 'status' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  created () {
    this.checkApprovedUser()
  },
  methods: {
    resetId () {
      this.testId = 0
    },
    default () {
      return {
        id: this.rows.length,
        name: '',
        warehouse_name: '',
        crop_id: '',
        crop_type_id: '',
        rent_amount: '',
        paid_rent_amount: '',
        release_quantity: '',
        remain_quantity: '',
        bank_receipt_no: ''
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(authServiceBaseUrl, '/user/farmer-list').then(response => {
        if (response.success) {
              this.farmerList = response.data
            }
        })

      await RestApi.getData(warehouseServiceBaseUrl, paymentCollectionList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async checkApprovedUser () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        const authUser = this.$store.state.Auth.authUser.username
        const result = await RestApi.getData(authServiceBaseUrl, userStatus + authUser)
        const status = result.status
        if (status === 1) {
            this.loadData()
        } else {
            this.$router.push({ name: 'external_user.warehouse_farmer' + '.dashboard' })
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
    }
  }
}
</script>
